import React from "react"

const PreloaderCircularView = ({color, size}) => {
	return (
		<div className={`preloader-wrapper active ${size ? size:""}`}>
			<div className={`spinner-layer spinner-${color ? "color": "blue"}-only`}>
				<div className="circle-clipper left">
					<div className="circle"/>
				</div>
				<div className="gap-patch">
					<div className="circle"/>
				</div>
				<div className="circle-clipper right">
					<div className="circle"/>
				</div>
			</div>
		</div>
	)
}

export default PreloaderCircularView
