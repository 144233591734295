import {createStore, applyMiddleware, compose} from "redux"
import {persistReducer} from "redux-persist"
import thunk from "redux-thunk"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import reducers from './reducers'

const persistConfig = {
	key: "root",
	storage: storage,
	stateReconciler: autoMergeLevel2,
	blacklist: ["readReducer", "homeReducer"],
}



export const configureStore = (initialStore = {}) => {

	const pReducer = persistReducer(persistConfig, reducers)

	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

	const store = createStore(pReducer, initialStore, composeEnhancers(applyMiddleware(thunk)))

	return store
}