import {combineReducers} from "redux";
import {
	article,
	articleHasErrored,
	articleIsLoading,
	comments,
	commentsError,
	commentsIsLoading,
	userHasVote,
	loadData,
	rateArticle,
} from "./ReadReducer";

export default combineReducers({
	article,
	articleHasErrored,
	articleIsLoading,
	comments,
	commentsError,
	commentsIsLoading,
	userHasVote,
	data: loadData,
	ratings: rateArticle,
});
