import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import Grid from "@material-ui/core/Grid"
import "./App.css"
import { Menubar } from "./components/Menubar/Menubar"
import { Footer } from "./components/Footer/Footer"
import Logout from "./modules/Logout/Logout"
import "./components/Icons/Icons"
import Preloader from "./components/Preloader"

const Home = lazy(() => import("./modules/Home/Home"))
const Read = lazy(() => import("./modules/Read/Read"))
const Profile = lazy(() => import("./modules/Profile/Profile"))
const Register = lazy(() => import("./containers/Register"))
const Write = lazy(() => import("./modules/Write/Write"))
const Recovery = lazy(() => import("./modules/Recovery"))
const NewPassword = lazy(() => import("./modules/NewPassword"))
const EditProfile = lazy(() => import("./containers/EditProfile"))
const Events = lazy(() => import("./modules/Events"))
const Rate = lazy(() => import("./containers/Rate"))
const Admin = lazy(() => import("./modules/Admin/containers/Admin"))
const AdminArticles = lazy(() => import("./modules/Admin/Articles"))
const AdminUsers = lazy(() => import("./modules/Admin/Users"))
const AdminTags = lazy(() => import("./modules/Admin/Tags"))
const AdminReports = lazy(() => import("./modules/Admin/Reports"))
const AdminAssets = lazy(() => import("./modules/Admin/Assets"))
const AdminModel = lazy(() => import("./modules/Admin/Model"))


const useAuth = () => {
	const user = useSelector(state => {
		return state.utilsReducer.user
	})
	return {
		isAdmin: !!user.isAdmin,
		isLogged: Object.keys(user).length > 0
	}
}

const PrivateRoute = ({ children, ...rest }) => {
	const auth = useAuth()
	return (
		<Route
			{...rest}
			render={({ location, ...props }) =>
				auth.isAdmin ? (
					React.cloneElement(children, props)
				) : <Redirect to={{ pathname: "/", state: { from: location } }} />
			} />
	)
}

const LoggedRoute = ({ children, ...rest }) => {
	const auth = useAuth()
	return (
		<Route
			{...rest}
			render={({ location, ...props }) =>
				auth.isLogged ? (
					React.cloneElement(children, props)
				) : <Redirect to={{ pathname: "/", state: { from: location } }} />
			} />
	)
}


const App = () => {
	return (
		<Suspense fallback={<Preloader />}>
			<Router>
				<React.Fragment>
					<Menubar />
					<main>
						<Grid container className={"app-container"}>
							<Route
								path={"/"}
								exact
								render={props => <Home {...props} />}
							/>
							<Route
								path={"/read/:title/:id"}
								name={"/read"}
								exact
								render={props => {
									return <Read {...props} />
								}}
							/>
							<LoggedRoute
								path={"/rate/:id"}
								name={"/rate"}
								exact
							>
								<Rate />
							</LoggedRoute>

							<LoggedRoute
								path={"/perfil/edit"}
								name={"/perfil/edit"}
								exact
							>
								<EditProfile />
							</LoggedRoute>
							<LoggedRoute
								path={"/perfil"}
								name={"/perfil"}
								exact
							>
								<Profile />
							</LoggedRoute>
							<Route
								path={"/events/:year?/:month?"}
								name={"/events"}
								exact
								render={props => {
									return <Events {...props} />
								}}
							/>
							<Route
								path={"/view-perfil/:id"}
								name={"/view-perfil"}
								exact
								render={props => {
									return <Profile {...props} view={true} />
								}}
							/>
							<Route
								path={"/logout"}
								exact
								render={props => {
									return <Logout {...props} />
								}}
							/>
							<LoggedRoute path={"/write"} exact>
								<Write />
							</LoggedRoute>
							<Route
								path={"/registro"}
								exact
								render={props => {
									return <Register {...props} />
								}}
							/>
							<Route
								path={"/recovery/:email?"}
								exact
								render={props => {
									return <Recovery {...props} />
								}}
							/>
							<Route
								path={"/new-password"}
								exact
								render={props => {
									return <NewPassword {...props} />
								}}
							/>
							<PrivateRoute exact path="/admin">
								<Admin />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/articles">
								<AdminArticles />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/users">
								<AdminUsers />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/assets/:section?">
								<AdminAssets />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/tags">
								<AdminTags />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/reports">
								<AdminReports />
							</PrivateRoute>
							<PrivateRoute exact path="/admin/model">
								<AdminModel />
							</PrivateRoute>
						</Grid>
					</main>
					<Footer />

				</React.Fragment>
			</Router>
		</Suspense>
	)

}

export default App
