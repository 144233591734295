export const writerState = {
	count: 0,
	suggestedTags: [],
	content: [],
	publishSuccess: false,
	publishStarted: false,
	publishError: null,
	urlImage: null,
	createdArticle: {},
};

const initialContent = [
	{
		type: "Title",
		content: {text: "", subtitle: ""},
	},
	{
		type: "Cuerpo",
		content: {
			title: "Cuerpo",
			text: "",
		},
	},
];

export const writerReducer = (state = {content: initialContent}, action) => {
	const content = state.hasOwnProperty("content") ? state.content : [];
	switch (action.type) {
	case "CREATE_NEW": {
		const newState = {
			...state,
			publishSuccess: false,
			publishStarted: false,
			publishError: null,
			suggestedTags: [],
			suggestedTagHasErrored: false,
			urlImage: null,
		}
		return newState;
	}
	case "RESET_ARTICLE": {
		const newState = {
			content: initialContent,
			createdArticle: {},
			publishSuccess: false,
			publishStarted: false,
			publishError: null,
			suggestedTags: [],
			suggestedTagHasErrored: false,
			urlImage: null,
		}
		return newState;
	}

	case "PUBLISH_STARTED":
		return {
			...state,
			publishStarted: true,
			publishError: null,
			publishSuccess: true,
		};
	case "PUBLISH_ERROR":
		return {
			...state,
			publishStarted: false,
			publishError: action.error,
			publishSuccess: false,
		};
	case "PUBLISH_SUCCESS":
		return {
			...state,
			publishStarted: false,
			publishError: null,
			publishSuccess: true,
			createdArticle: action.payload,
		};

	case "SET_SUGGESTED_TAGS":
		return {...state, suggestedTags: action.payload.suggestedTags};

		/*
		case "EDITED_CONTENT": {
			var newContent = content;

			newContent[action.index] = {
				...newContent[action.index],
				content: action.data,
			};

			var additional = {}
			if (action.data.hasOwnProperty("date")) {
				additional = {eventDate: new Date(action.data.date).toISOString()}
			}
			return { ...state, content: newContent, ...additional };
		}*/

	case "EDITED_CONTENT": {
		const newContent = content;

		newContent[action.index] = {
			...newContent[action.index],
			content: action.data,
		};
		return {...state, content: newContent};
	}

	case "MOVE_CONTENT": {
		const from = action.from;
		const to = action.to;
		const superIndex = action.superIndex;

		if (to === from) {
			return {...state};
		}

		if (superIndex > -1) {
			const parent = content[superIndex];

			parent.content = parent.content.reduce((acc, item, index) => {
				if (index === to) {
					return [...acc, parent.content[from], item];
				} else if (index === from) {
					return acc;
				}
				return [...acc, item];
			}, []);

			return {
				...state,
				content,
			};
		}

		const newContent = content.reduce((acc, item, index) => {
			if (index === to) {
				if (to > from) {
					return [...acc, item, content[from]];
				} else {
					return [...acc, content[from], item];
				}
			} else if (index === from) {
				return acc;
			}
			return [...acc, item];
		}, []);

		return {
			...state,
			content: newContent,
		};
	}

	case "ADD_RANKING_CHILD": {
		const newContent = content;
		const ranking = newContent[action.index];

		newContent[action.index] = {
			type: ranking.type,
			content: ranking.content.concat({title: "new item", text: ""}),
		};
		return {
			...state,
			content: newContent,
		};
	}
	case "ADD_CONTENT": {
		const data = action.contentData;
		const newContent = content.concat(
			data !== "undefined"
				? [{type: action.elementType, content: data}]
				: [],
		);
		return {
			...state,
			content: newContent,
		};
	}
	case "REMOVE_CONTENT": {
		const index = action.index
		const newContent = content.filter((_, i) => i !== index)
		return {...state, content: newContent}
	}
	case "SUGGESTED_TAGS_LOADED":
		return {
			...state,
			suggestedTags: action.items.map((item) => ({
				...item,
				typeId: item.t2Id,
				nameId: item.t1Id,
			})),
		};

	case "SUGGESTED_TAGS_ERROR":
		return {...state, suggestedTagHasErrored: action.bool};

	case "STORE_IMAGE_SUCCESS":
		return {
			...state,
			urlImage: action.imageUrl.url,
			publisError: null,
		};
	case "STORE_IMAGE_ERROR":
		return {...state, publisError: action.error};

	case "REMOVE_IMAGE":
		return {...state, urlImage: null};
	case "SET_IMAGE":
		return {...state, urlImage: action.url};
	default:
		return state;
	}
};
