const initialState = {
	users: [],
	articles: [],
	assets: [],
	assetsCreation: {started: false, success: false, error: null, hasError: false},
	analytics: {},
	adminWorking: false,
}

const deleteArticles = (articles, payload) => {
	if (!payload.length) return articles
	const newArticles = articles.filter((art) => !payload.includes(art.id))
	return newArticles
}

const Admin = (state = initialState, action) => {
	switch (action.type) {
	case "admin/users_fetch_success":
		return {...state, users: action.payload}
	case "admin/articles_delete_success":
		return {...state, articles: deleteArticles(state.articles, action.payload), adminWorking: false}
	case "admin/articles_delete_loading":
		return {...state, adminWorking: true}
	case "admin/articles_delete_error":
		return {...state, adminWorking: false}
	case "admin/articles_fetch_success":
		return {...state, articles: action.payload}
	case "admin/assets/load_success":
		return {...state, assets: action.payload}


	case "admin/assets/twitter/create_success":
		return {...state, assetsCreation: {started: false, success: true, error: null, hasError: false}}
	case "admin/assets/image/create_success":
		return {...state, assetsCreation: {started: false, success: true, error: null, hasError: false}}
	case "admin/assets/refered/create_success":
		return {...state, assetsCreation: {started: false, success: true, error: null, hasError: false}}

	case "admin/assets/twitter/create_started":
		return {...state, assetsCreation: {started: true, success: false, error: null, hasError: false}}
	case "admin/assets/image/create_started":
		return {...state, assetsCreation: {started: true, success: false, error: null, hasError: false}}
	case "admin/assets/refered/create_started":
		return {...state, assetsCreation: {started: true, success: false, error: null, hasError: false}}

	case "admin/assets/twitter/create_error":
		return {...state, assetsCreation: {started: false, success: false, error: action.error, hasError: true}}
	case "admin/assets/image/create_error":
		return {...state, assetsCreation: {started: false, success: false, error: action.error, hasError: true}}
	case "admin/assets/refered/create_error":
		return {...state, assetsCreation: {started: false, success: false, error: action.error, hasError: true}}


	case "admin/tax/assets/fetch_success":
		return {...state, analytics: {...state.analytics, taxAssets: action.payload}}

	case "admin/assets/analytics/user/fetch_success":
		return {...state, analytics: {...state.analytics, user: action.payload}}
	case "admin/assets/analytics/article/fetch_success":
		return {...state, analytics: {...state.analytics, article: action.payload}}
	default:
		return state
	}
}

export default Admin
