import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"

const detectionOptions = {
	order: ["querystring", "localStorage", "navigator"],
	lookupQuerystring: "lng"
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(resourcesToBackend((language, namespace, callback) => {
		import(`./locales/${language}/translation.json`)
			.then((resources) => {
				callback(null, resources)
			})
			.catch((error) => {
				callback(error, null)
			})
	}))
	.init({
		//preload: ["en", "es"],
		ns: ["translation"],
		defaultNS: "translation",
		fallbackLng: ["en", "es"],
		saveMissing: true,
		react: {
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false,
			format: function (value, format, lng) {
				if (format === "uppercase") return value.toUpperCase()
				if (format === "lowercase") return value.toLowerCase()
				if (format === "capitalize") return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`
				return value
			}
		},
		debug: false,
		detection: detectionOptions
	}, (err, t) => {
		if (err) return console.error(err)
		console.log("i18next is ready...")
		console.log(t("welcome", { lng: "es" }))
	})

export default i18n
