import {combineReducers} from "redux"
import writerReducer from "../modules/Write/reducer"
import homeReducer from "../modules/Home/reducer"
import readReducer from "../modules/Read/reducer"
import profileReducer from "../modules/Profile/reducer"
import feedbackReducer from "../modules/Rate/reducer"
import registerReducer from "../modules/Register/reducer"
import utilsReducer from "../utils/reducer"
import filtersReducer from "../components/Filters/reducer"
import Admin from "../modules/Admin/reducer"
import {eventsReducer} from "../components/Widgets/reducer/EventsReducer"
import configReducer from "./defaults"


const reducers = combineReducers({
	utilsReducer,
	writerReducer,
	readReducer,
	homeReducer,
	filtersReducer,
	profileReducer,
	eventsReducer,
	configReducer,
	Admin,
	feedbackReducer,
	registerReducer,
})


export default reducers