import {combineReducers} from "redux"
import {articles,loadingArticles, filteredArticles, articlesHasErrored, twitterAccounts, twitterAccountsHasErrored} from "./HomeReducer"


export default combineReducers({
	articles,
	loadingArticles,
	filteredArticles,
	articlesHasErrored,
	twitterAccounts,
	twitterAccountsHasErrored,
})
