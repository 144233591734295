import {library} from "@fortawesome/fontawesome-svg-core"
import {
	faBan,
	faDownload,
	faEye,
	faEyeSlash,
	faFile,
	faSave,
	faThumbsDown,
	faThumbsUp,
	faUser,
	faAngleLeft,
	faArrowsAlt,
	faStream,
	faListOl,
	faPoll,
	faStar,
	faChevronRight,
	faTrashAlt,
	faPencilAlt,
	faMicrophone,
	faQuoteRight,
	faBullseye,
	faCalendarDay,
	faUndo,
	faRedo,
	faBold,
	faItalic,
	faUnderline,
	faStrikethrough,
	faTable,
	faImage,
	faTimes,
	faPodcast,
} from "@fortawesome/free-solid-svg-icons"
import {
	faThumbsDown as farThumbsDown,
	faThumbsUp as farThumbsUp,
} from "@fortawesome/free-regular-svg-icons"
import {
	faFacebookSquare,
	faTwitter,
	faRedhat,
	faYoutube,
	faInstagram,
	faStrava,
} from "@fortawesome/free-brands-svg-icons"

library.add(faEye)
library.add(faBan)
library.add(faFile)
library.add(faUser)
library.add(faThumbsUp)
library.add(farThumbsUp)
library.add(faThumbsDown)
library.add(farThumbsDown)
library.add(faEyeSlash)
library.add(faSave)
library.add(faDownload)
library.add(faFacebookSquare)
library.add(faTwitter)
library.add(faAngleLeft)
library.add(faArrowsAlt)
library.add(faStream)
library.add(faListOl)
library.add(faPoll)
library.add(faStar)
library.add(faChevronRight)
library.add(faTrashAlt)
library.add(faPencilAlt)
library.add(faMicrophone)
library.add(faQuoteRight)
library.add(faBullseye)
library.add(faCalendarDay)
library.add(faUndo)
library.add(faRedo)
library.add(faBold)
library.add(faItalic)
library.add(faUnderline)
library.add(faStrikethrough)
library.add(faTable)
library.add(faRedhat)
library.add(faImage)
library.add(faTimes)
library.add(faYoutube)
library.add(faInstagram)
library.add(faPodcast)
library.add(faStrava)
