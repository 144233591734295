const defaultConfig = {
	content: {
		max_title_length: 80,
		max_subtitle_length: 160,

		max_event_name_length: 80,

		max_ranking_title_length: 60,

		max_forcast_statement_length: 80,
		max_forcast_event_length: 60,
		default_forcast_statement: "Alguien ganará",
		default_forcast_event: "Nombre Evento",

		max_spotlight_lenght: 100,

		max_interview_question_length: 255,

		max_poll_question_length: 160,
		max_poll_option_length: 80,


	},
}
const configReducer = (state = defaultConfig, action) => {
	switch (action.type) {
	default:
		return state
	}
}

export default configReducer
