export function articlesHasErrored(state = false, action) {
	switch (action.type) {
	case "ARTICLES_FETCH_ERROR":
		return action.bool
	default:
		return state
	}
}

export function filteredArticles(state = [], action) {
	switch (action.type) {
	case "ARTICLES_FETCH_SUCCESS":
		return action.items
	case "ARTICLES_FILTERED":
		return action.items
	default:
		return state
	}
}

export function loadingArticles(state = false, action) {
	switch(action.type) {
	case "ARTICLES_FETCH_LOADING":
		return action.bool
	case "ARTICLES_FETCH_SUCCESS":
	case "ARTICLES_FETCH_ERROR":
		return false
	default:
		return state
	}
}

export function articles(state = [], action) {
	switch (action.type) {
	case "ARTICLES_FETCH_SUCCESS":
		return action.items
	default:
		return state
	}
}

export function twitterAccountsHasErrored(state = false, action) {
	switch (action.type) {
	case "TWITTER_ACCOUNTS_ERROR":
		return action.bool
	default:
		return state
	}
}

export function twitterAccounts(state = [], action) {
	switch (action.type) {
	case "TWITTER_ACCOUNTS_FETCH_SUCCESS":
		return action.items
	default:
		return state
	}
}
