export const user = (state = {}, action) => {
	switch (action.type) {
	case "AUTH": {
		return action.user
	}
	case "LOGOUT": {
		return {}
	}
	default:
		return state
	}
}
