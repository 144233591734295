import {combineReducers} from "redux"
import {filters, filtersHasError, createStatus, taxonomias, ejes,filterMode} from "./FiltersReducer"


export default combineReducers({
	filters,
	filtersHasError,
	createStatus,
	taxonomias,
	ejes,
	filterMode
})
