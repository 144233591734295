import React from "react"
import ReactDOM from "react-dom"
import "./index.css"

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Provider} from "react-redux"
import { persistStore } from "redux-persist";
import {PersistGate} from "redux-persist/lib/integration/react"
import {configureStore} from "./state/state"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./i18n"

/* eslint-disable no-extend-native */
String.prototype.replaceAll = function(search, replacement) {
	const target = this
	return target.split(search).join(replacement)
}
/* eslint-enable no-extend-native */

const theme = createTheme({

})

const store = configureStore()

const persistor = persistStore(store)

ReactDOM.render(<Provider store={store}>
	<PersistGate persistor={persistor}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</PersistGate>
</Provider>,
document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
