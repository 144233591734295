import React from "react"
import "./styles/Footer.css"
import Grid from "@mui/material/Grid"

export const Footer = () => {
	return (
		<Grid item xs={12}>
			<footer className='page-footer'>
				<div className='footer-copyright'>
					<div className='container'>© 2022 Ejedeportivo</div>
				</div>
			</footer>
		</Grid>
	)
}
