const register = (state= {status: {error:false, loading:false, errorMessage: null}}, action) => {
	switch(action.type) {
		case 'register/create/success': {
			return {...state, status: {error:false, loading: false, errorMessage: null}}
		}
		case 'register/create/error': {
			return {...state, status: {error:true, loading: false, errorMessage: action.payload}}
			
		}
		case 'register/create/loading': {
			return {...state, status: {error:false, loading: true, errorMessage: null}}
		}
		default:
			return state
	}
}

export default register