import React from "react"

const PreloaderLinearView = ({active}) => {
	return (
		<div className={active ? "progress" : ""}>
			<div className="indeterminate"></div>
		</div>
	)
}

export default PreloaderLinearView
