export const ADVANCED = 0
export const SIMPLE = 1
export const EJE = 2

export function filtersHasError(state = false, action) {
	switch (action.type) {
		case "FILTERS_FETCH_ERROR":
			return action.bool
		default:
			return state
	}
}

export function filterMode(state = ADVANCED, action) {
	switch(action.type) {
		case "filters/mode":
			return action.mode
		default:
			return state
	}
}

export function filters(state = [], action) {
	switch (action.type) {
		case "FILTERS_FETCH_SUCCESS":
			return action.items
		default:
			return state
	}
}

export function ejes(state = { filterMode: ADVANCED, filtering: [], save: { success: false, error: false, loading: false }, ejes: [], get_ejes_error: false, loading_ejes: false }, action) {
	switch (action.type) {
		case "filters/eje/update":
			const colors = action.groupInfo ?? state.filtering
			console.log(colors)
			const newFiltering = Array.from(action.filtering.keys()).map(group => {
				const groupInfo = colors.find((item) => item.name === group)
				return {name: group, color: groupInfo.color, filters: action.filtering.get(group)}
			})
			return {...state, filtering: newFiltering}
		case "filters/eje/remove":
			return { ...state, filtering: state.filtering.reduce((acc, item) => item.name === action.eje ? acc : acc.concat([item]), []) }
		case "filters/eje/reset":
			return { ...state, filtering: [] }
		case "filters/eje/create":
			return { ...state, filtering: [...state.filtering, { name: action.name, color: action.color }] }
		case "filters/eje/save/success":
			return { ...state, save: { success: true, error: false, loading: false } }
		case "filters/eje/save/error":
			return { ...state, save: { success: false, error: true, loading: false } }
		case "filters/eje/save/loading":
			return { ...state, save: { success: false, error: false, loading: true } }
		case "filters/eje/get/success":
			return { ...state, ejes: action.items, get_ejes_error: false, loading_ejes: false }
		case "filters/eje/get/error":
			return { ...state, ejes: [], get_ejes_error: true, loading_ejes: false }
		case "filters/eje/get/loading":
			return { ...state, get_ejes_error: false, loading_ejes: true }
		default:
			return state
	}
}


export function taxonomias(state = [], action) {
	switch (action.type) {
		case "tax/load_success":
			return action.items
		default:
			return state
	}
}


export function createStatus(state = { started: false, success: false, error: null, hasError: false }, action) {
	switch (action.type) {
		case "tax/create_success":
			return {
				...state,
				started: false,
				success: true,
				error: null,
				hasError: false,

			}
		case "tax/create_error":
			return {
				...state,
				started: false,
				success: false,
				error: action.error,
				hasError: true,

			}
		case "tax/create_started":
			return {
				...state,
				started: true,
				success: false,
				error: null,
				hasError: false,

			}
		default:
			return state
	}
}


