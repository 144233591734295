import React from "react"
import PreloaderLinearView from "./Views/PreloaderLinearView"
import PreloaderCircularView from "./Views/PreloaderCircularView"
import PropTypes from "prop-types"

/**
 * @desc
 *  It is necessary to define a ref to get access to the preloader methods
 * @props
 * @param {bool} active
 * @param {string|null} type [circular|linear]: Defines the view to use. Default circular
 * @param {string|null} size [big|medium|small]: Defines the size. Only for circular preloader. Default medium
 * @param {string|null} color: Defines the color. Only for circular preloader. Default blue
 */
const Preloader = (props) => {
	const buildPreloaderView =() => {
		let preloaderView = null
		const type = props.type ? props.type : "circular"

		switch (type) {
		case "linear":
			preloaderView = <PreloaderLinearView {...props} active={props.active}/>
			break
		case "circular":
			preloaderView = <PreloaderCircularView {...props} active={props.active}/>
			break
		default:
			throw Error("Invalid preloader type")
		}
		return preloaderView
	}

	return (buildPreloaderView())
}

export default Preloader

Preloader.propTypes = {
	type: PropTypes.string,
}
