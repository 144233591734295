import React from "react"
import logo from "../../logo.png"
import {Link} from "react-router-dom"
import "./styles/Menubar.css"

export const Menubar = () => {
	return (
		<header className="menubar">
			<div className={"logo-section"}>
				<div className="mobile-menu">
					<i className="fa fa-bars" />
				</div>
				<h1 className="logo">
					<Link to="/" className="center-align">
						<img src={logo} width={200} height={30} alt={"logo"} />
					</Link>
				</h1>
			</div>
		</header>
	)
}
