export function eventsReducer(state = {events: [], loading: false, error: false}, action) {
	switch (action.type) {
	case "EVENT_FETCH_ERROR":
		return {...state, events: [], loading: false, error: action.bool};
	case "EVENT_FETCH_LOADING":
		return {...state, events: [], loading: action.bool};
	case "EVENT_FETCH_SUCCESS":
		return {...state, events: action.items, loading: false, error: false};
	default:
		return state;
	}
}
