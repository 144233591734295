export function profile(state = {}, action) {
	switch (action.type) {
	case "PROFILE_FETCH_SUCCESS":
		return {...state, ...action.item}
	case "UPDATE_PROFILE_SUCCESS":
		return {...state, ...action.item}
	case "BADGE_FETCH_SUCCESS":
		return {...state, badge: action.item}
	default:
		return state
	}
}

export function userGallery(state = [], action) {
	switch (action.type) {
	case "profile/user_gallery_success":
		return action.items
	default:
		return state
	}
}

export function profileError(state = false, action) {
	switch (action.type) {
	case "PROFILE_FETCH_ERROR":
	case "UPDATE_PROFILE_ERROR":
		return action.bool
	default:
		return state
	}
}

export function profileIsLoading(state = false, action) {
	switch (action.type) {
	case "PROFILE_FETCH_LOADING":
	case "UPDATE_PROFILE_LOADING":
		return action.bool
	default:
		return state
	}
}
