import React, {useEffect} from "react"
import {Redirect} from "react-router-dom"
import {connect} from "react-redux"

const Logout = ({logout}) => {
	useEffect(() => {
		return () => {
			logout()
		}
	}, [logout])

	return <Redirect to={"/"} />
}

const mapStateToProps = (state) => ({
	user: state.utilsReducer.user,
})

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch({type: "LOGOUT"}),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
