export function articleHasErrored(state = false, action) {
	switch (action.type) {
	case "ARTICLE_FETCH_ERROR":
		return action.bool
	default:
		return state
	}
}

export function articleIsLoading(state = false, action) {
	switch (action.type) {
	case "ARTICLE_FETCH_LOADING":
		return action.bool
	default:
		return state
	}
}

export function article(state = {}, action) {
	switch (action.type) {
	case "ARTICLE_FETCH_SUCCESS":
		return action.item[0]
	default:
		return state
	}
}


export function comments(state = [], action) {
	switch (action.type) {
	case "LOAD_COMMENTS_SUCCESS":
		return action.item
	default:
		return state
	}
}

export function commentsError(state = false, action) {
	switch (action.type) {
	case "LOAD_COMMENTS_ERROR":
		return action.bool
	default:
		return state
	}
}

export function commentsIsLoading(state = false, action) {
	switch (action.type) {
	case "LOAD_COMMENTS_LOADING":
		return action.bool
	default:
		return state
	}
}

export function userHasVote(state = false, action) {
	switch (action.type) {
	case "POLL_VOTE_SUCCESS":
		return {...state, hasVotePoll: action.item}
	case "FORCAST_VOTE_SUCCESS":
		return {...state, hasVoteForcast: action.item}
	default:
		return state
	}
}


export function loadData(state = false, action) {
	switch (action.type) {
	case "LOAD_POLL_SUCCESS":
		return {...state, poll: action.item}
	case "LOAD_FORCAST_SUCCESS":
		return {...state, forcast: action.item}
	default:
		return state
	}
}

export function rateArticle(state = {}, action) {
	switch (action.type) {
	case "read/rate_success":

		return {...state, ...action.payload, loading: false}
	case "read/rate_started":
		return {...state, success: false, loading: true}
	case "read/rate_error":
		return {...state}
	default:
		return state
	}
}
